import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from "react-helmet";
import { StaticImage } from 'gatsby-plugin-image'
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import '../css/Instructions.css';

function InstructionsPage() {
  return (
    <div className="instructions-big-wrapper">
      <Helmet htmlAttributes={{lang: 'sv'}}>
        <meta charSet="utf-8" />
        <title>Instruktioner för att skapa en egen personlig kalender</title>
        <meta name="description" content="Instruktioner för att skapa en kalender med Familjens kalender. Hur du kan anpassa din kalender och hur du skriver ut kalendern som pdf." />
      </Helmet>
      <div className="page-default-grid-layout">
        <header className="app-header">
          <div className="app-header-aside-left">
          </div>
          <div className="app-header-center">
            <h1>Familjens kalender</h1> 
            <h2>Skapa en egen personlig kalender</h2>
            <h3>Dela din personliga kalender med familj och vänner som PDF eller som utskrift</h3>
            <button className="app-header-button-click-me"><Link to="/kalender" >Designa din kalender</Link></button>
            </div>  
          <div className="app-header-aside-right">
          </div>
        </header>   
        <nav className="app-nav">
          <Navigation />
        </nav>

        <main className="page-default-main">
          <h1>Instruktioner för att skapa en egen gratis personlig kalender</h1>
          <br/>
          <h2>Skapa en egen personlig kalender</h2>
          <p>
            För att skapa och designa din personliga kalender går du först till sidan <Link to="/kalender">Kalender</Link>.
            Där hittar du olika inställningar du kan använda för att skapa en personlig kalender efter dina önskemål och möjligheten att välja egna bilder 
            du vill ha med i din kalender. 
          </p>
          <br/>
          <h3>Kalenderns inställningar</h3>
          <p>
            Några inställningar som finns är att du kan välja vilket år du vill skapa en kalender för. 
            Du kan lägga in födelsedagar för personer (eller annan text) på olika datum.
            Det går också att lägga in namnsdagar som du vill ska synas lite extra, fetmarkerade, i din kalender,
            möjligen familjemedlemmars eller nära vänners födelsedagar och namnsdagar.
            För födelsedagar har du även möjlighet att välja en färg på texten som visas. 
            Tänk på att vissa färger inte kommer synas lika bra vid en utskrift.  
          </p>
          <br/>
          <p>
            Du kan även välja att ta bort viss information från din kalender t.ex. att inte visa alla namnsdagar, året eller temadagar.
            Väljer du bort att visa namnsdagar så kommer bara de namnsdagar du själv lagt in att visas.
          </p>
          <br/>
          <p>
            <strong>Notera att om du stänger kalendersidan medan du arbetar med den, 
              byter till en annan sida eller uppdaterar sidan så kommer dina ändringar att försvinna. </strong>
            För att undvika att behöva göra om ditt arbete med att lägga in t.ex.
            födelsedagar och namnsdagar så finns det möjlighet att spara ner dessa till en fil på din dator som
            du sedan kan ladda upp igen (kommer skriva över andra inställningar).
            Detta kan vara bra om du vill fortsätta senare eller återanvända dina inställningar för nästa års kalender.
            Det är bara inställningarna som går att spara idag och inte bilder du lagt till i kalendern.
          </p>
          <br/>
          <h3>Bilder till kalendern</h3>
          <p>
            Efter att du ordnat med kalenderns textinnehåll så är nästa steg att fundera på hur många bilder du vill ha med för en viss kalendermånad.
            Du kan välja om du bara vill ha en stor bild eller flera mindre genom att välja något av de layoutalternativ som finns ovanför varje kalendermånad.
            Det automatiskt förvalda alternativet är 1 x 1, en rad och en kolumn, vilket resulterar i en stor bild.
            Några andra alternativ är t.ex. 1 x 2 för två bilder, 2 x 2 för fyra bilder och det finns även möjlighet att trycka på knappen <i>Egen</i> för
             flera alternativ på kalenders bildlayout. 
          </p>
          <br/>
          <p>När du har valt hur många bilder du vill ha med för kalendermånaden så är nästa steg att lägga in bilderna du vill ha med i kalendern.
             Du kan göra detta genom att klicka på rutan där bilden ska vara och sedan välja en bild bland dina filer.
             Det går också att dra en bild från t.ex. en mapp du förberett med bilden och sedan släppa den valda bilden i den ruta du vill att bilden ska synas.
          </p>
          <br/>
          <p>
            För varje bild har du möjlighet att flytta runt bilden och zooma in eller zooma ut.
            Detta ger dig möjligheten att få med det du tycker är viktigast och anpassa bilden efter storleken på bildrutan.
            När du är klar med bildens placering kan det vara bra att låsa bilden så att du inte flyttar på den av misstag.
            Du kan även återställa bilden till dess startläge eller ta bort bilden vid behov.
          </p>

          <br/>
          <h3>Spara och skriv ut kalendern</h3>
          <p>
            När du är nöjd med din kalender så är det sista steget att spara ner din kalender. Det gör du genom att skriva ut kalendern i din webbläsare. 
          </p>
          <br/>
          <p>
            Det är <strong>rekommenderat att skriva ut kalendern som PDF</strong> så att du sparar kalendern på din dator.
            Sedan kan du välja om du vill skriva ut den på papper eller kanske skicka den digital till någon annan. 
            Skriver du ut kalendern till papper kan du t.ex. hänga upp kalender på väggen eller sätta den på kylskåpet med magneter.
          </p>

          <br/>
          <p>
            För att skriva ut kan du kan klicka på knappen <i>Skriv ut din kalender</i>. Detta öppnar skriv ut funktionen i din webbläsare.
            Det går ofta även att högerklicka på sidan för att få alternativet att skriva ut eller trycka <i>Ctrl p</i> på tangentbordet. 
          </p>
          <br/>
          <p>
             Notera att kalendern idag bara är <strong> anpassad för formatet A4</strong>. För andra format finns risken att kalendern inte kommer att visas på ett korrekt sätt.
             Om bakgrundsfärgen försvinner när du skriver ut så finns det en skrivarinställning för det, 
            <i> Skriv ut bakgrunder</i> eller <i>Bakgrundsgrafik</i> ofta under <i>Fler inställningar</i>, som du behöver bocka för.
            Utskriften av kalendern kan variera beroende på vilken webbläsare du använder (Chrome, Firefox, Edge, Safari etc) och din webbläsares skrivarinställningar.   
          </p>
          <br/>

          <p>
            <strong>Innan du avslutar och lämnar <i>Familjens kalender</i>, fundera på om du vill spara ner dina inställningar till en fil som du kan använda för nästa års kalender.
            För att spara ner dina kalenderinställningar kan du tryck på knappen <i>Spara ner inställningar</i>.
            </strong>
          </p>
          <br/>
          
          <h2>Bilder och fler detaljer för att skapa en personlig kalender</h2>
          <br/>
          <h3>Kalenderinställningar</h3>
          <p>När du går till sidan <Link to="/kalender">Kalender</Link> så visas först olika inställningar du kan använda för att skapa en mer personlig kalender
           efter dina egna önskemål och behov. 
          </p>
          <br/>
          <p>Bild på inställningar du kan använda för att skräddarsy din kalender visas här nedanför.</p>
          <br/>
          <StaticImage
                src="../images/calendar-settings-2022.png"
                alt="Bild på möjliga kalenderinställningar"
          />  
          <br/>
          <br/>
          <h4>
            De möjligheter som finns idag för att anpassa och hantera din kalender är att du kan:
          </h4>
          <ul className="instructions-list">
            <li>Spara ner inställningar för kalendern, för att kunna använda dem igen senare</li>
            <li>Ladda upp sparade kalenderinställningar, för att kunna återanvända tidigare sparat arbete</li>
            <li>Välja vilket kalenderår du vill skapa en kalender för</li>
            <li>Välja vilken månad du vill skapa en kalender för (om du inte vill ha med alla månader)</li>
            <li>Lägga in födelsedagar i kalendern</li>
            <ul>
                <li>Du kan skriva in namnet på personen som du vill lägga till (eller valfri text)</li>
                <li>Du kan skriva in födelseåret på personen som du vill lägga till för att få med åldern (valfritt)</li>
                <li>Du kan välja månand och dag för när personen fyller år (födelseåret behövs inte)</li>
                <li>Du kan välja färg på den text som sedan syns i kalendern</li>
              </ul>
            <li>Lägga in namnsdagar i kalendern</li>
            <li>Göra olika val för vad som ska visas i kalendern
              <ul>
                <li>Om alla namnsdagar ska visas i kalendern</li>
                <li>Om du vill få med året tillsammans med månaden på kalendern</li>
                <li>Om du vill få med röda dagar i kalendern</li>
                <li>Om du vill få med flaggdagar i kalendern</li>
                <li>Om du vill få med andra temadagar i kalendern</li>
              </ul>
            </li>
            <li>Klicka på skriv ut för att enklare skriva ut din kalender</li>
          </ul> 

          <br/>

          <h4>Födelsedagar och namnsdagar till kalendern</h4>
          <p>
           För att lägga in födelsedagar så skriver du in namnet på personen och sedan vilken månad och dag som personen fyller år. Du kan också välja vilken färg 
           som namnet visas i kalendern. Ångrar du dig eller vill du ändra något så kan du ta bort födelsedagen genom att klicka på kryssknappen bredvid namnet.
        
          </p>
          <br/>
          <p>
           För namnsdagar så kan du lägga till de namn du vill ska visas, dessa blir fetmarkerade så att de syns lite extra. Tar du bort att inte visa alla namnsdagar så
           kommer de namnsdagar du själv lagt till fortfarande visas. Bara namnsdagar som finns i den svenska kalendern kommer att dyka upp.
           Till exempel Kalle och Stina kommer inte att visas även om du lagt till namnen likt exemplet nedan då dessa inte finns med idag 
           (<a href="https://sv.wikipedia.org/wiki/Lista_%C3%B6ver_namnsdagar_i_Sverige_i_datumordning">namnsdagar</a>).
            Tänk också på att stavningen på namnet påverkar, Rikard kommer med men inte Richard.    
          </p>
          <br/>
          <StaticImage
                src="../images/calendar-birthdays-namedays.png"
                alt="Bild på möjliga födelsedagar och namnsdagar för en personliga kalender"
          />  
          <br/>
          <br/>
          <h3>Layout till din personliga kalender</h3>
          <p> 
            När du kollar på en kalendermånad kommer du se en tom ruta ovanför alla datum som är tänkt för bilder till din kalender. 
            Du kan välja antalet bilder för varje månad och hur de ska placeras. Idag kan du ha mellan en (1 x 1) och 16 (4 x 4) bilder per kalendermånad.  
          </p>
          <br/>
          <p> 
            Klicka på någon av knapparna ovanför kalendermånaden för att välja vilken layout du vill ha.
            Klickar du på knappen <i>Egen</i> så kan du själv välja hur många rader och antalet bilder per rad.   
          </p>
          <br/>
          <StaticImage
                src="../images/calendar-example-jan.png"
                alt="Bild på en personliga kalender utan bilder med layout 1 x 1 för januari"
          />  
          
          <h3>Bilder till din personliga kalender</h3>
          <p> 
            Klicka på en av de tomma bildrutorna för att välja en bild eller dra bilden och släpp den på den bildruta du vill att bilden ska visas i.
            För varje bild kan du sedan justera hur den ska visas. Du ansvar själv för om du får använda de bilderna du använder.
          </p>
          <br/>
          <p> 
            Du kan zooma in eller zooma ut bilden genom att klicka på någon av knapparna som dyker upp när du håller datormusen ovanför bilden (se bilden nedan)
             eller genom att scrolla med musen medan du håller musen ovanför bilden. 
            Du kan även flytta på bilden genom att hålla nere vänster musknapp på bilden samtidigt som du drar bilden dit du vill. 
          </p>
          <br/>

          <p>  
            Det är bra att låsa bilden när du är nöjd genom att trycka på låsknappen ovanför bilden. Detta gör så att du inte av misstag råkar ändra på bilden.
             Du kan låsa upp igen om du vill ändra något senare.
          </p>
          <br/>
          <StaticImage
                src="../images/calendar-example-jan-with-images.png"
                alt="Bild på en personlig kalender med bilder med layout 2 x 2 för januari"
          />  
          <h3>Skriva ut din personliga kalender</h3>
          <p> 
            När du är färdig med att lägga till bilder till din kalender och känner dig nöjd kan du sedan skriva ut kalendern via webbläsaren.
            Du kan klicka på knappen <i>Skriv ut din kalender</i> för att öppna skriv ut funktionen i din webbläsare.
            Det går ofta att göra på flera sätt, högerklicka och kolla efter ett skriv ut alternativ,
            använda <i>Ctrl p</i> eller klicka på webbläsarens meny och hitta ett skriv ut alternativ där.
          </p>
          <br/>

          <p> 
          Ta bort sidhuvud och sidfot om du inte vill ha med dessa, ofta gör man detta under <i>Fler inställningar/alternativ</i>.
            Notera att för vissa webbläsares skrivare behöver man lägga till att bakgrundsfärgen ska komma med, 
            <i> Bakgrundsgrafik</i> är förbockad i bilden i exemplet nedan (behövs för t.ex. Firefox).  
          </p>
          <br/>

          <p> 
            Det är rekommenderat att skriva ut kalendern som PDF först om du vill kunna spara den.
            Välj alternativet <i>Spara som PDF</i> eller liknande och spara sedan kalendern.
            Sedan kan du öppna PDF-filen med kalendern och dela den med andra eller skriva ut den på papper. 
          </p>
          <br/>

          <StaticImage
                src="../images/calendar-example-done-print-2.png"
                alt="Bild på en personlig kalender som kan skrivas ut"
          />  
          <h2>För de med HTML och CSS kunskaper</h2>
          <p>Eftersom kalendern byggs upp av HTML element och får sitt utseende av CSS så kan man i webbläsaren även gå in och ändra på detaljer själv.
             T.ex. om du vill ha en annan färg, vill ändra vilken text som visas eller vilken teckensnitt
             så går det att göra själv genom att till exempel inspektera elementet och ändra innehållet där. Detta görs på egen risk.
          </p>

          <br/>
          <h2>Begränsningar</h2>
          <p>
            Det finns vissa begränsningar på vad som är möjligt att göra idag på <i>Familjens kalender</i>.
            Till exempel så finns bara stöd för att skapa en kalender på svenska anpassad för Sverige och A4 format. 
            Applikationen fungerar idag bäst på en dator där skärmstorlekten är större.
            Kalendern går bara tillbaka till 2018 och fram till 2025,
             detta för att t.ex. namnsdagar har ändrats mellan åren och applikationen inte är anpassad för alla år än.
             År framåt kan också få ändrade temadagar, flaggdagar och namnsdagar så risken finns att även dessa kan bli felaktiga.  
          </p> 


          <br/>
          <h2>Vad händer med mina bilder och min information?</h2>
          <p>
            Det är viktigt att dina personliga bilder kan användas tryggt.
            Inga bilder som du använder skickas iväg eller sparas av applikationen på någon server, utan finns bara lokalt i din webbläsare på din dator.
          </p> 
          <br/>
          <p>
            För dina inställningar, som födelsedagar och namnsdagar du lägger till, så sparas inte heller detta av applikationen på någon server.
            Det finns bara lokalt i din webbläsare. Du har dock möjligheten att spara dessa till en fil på din dator. 
            Din webbläsare kan dock spara information du skrivit in, t.ex. namnsdagar och födelsedagar så att dessa sedan kan ges som förslag (autofyll).
            Denna webbinformation borde du kunna rensa i din webbläsare vid behov.
          </p> 
          
          <br/>
          <p>
            Läs mer i <i>Familjens kalenders</i> <Link to="/integritet-och-anvandarvillkor">integritetspolicy</Link> om du vill veta mer.
          </p> 
          <br/>

          <h2>Önskemål och feedback</h2>
          <p>Om du har några önskemål på saker du skulle vilja ha med i din personliga <Link to="/kalender">kalender</Link> som inte finns idag, hittat något som är fel eller bara vill säga hej så kan du skicka ett
             mail till info@familjenskalender.se så försöker jag ta en titt på det.  
          </p> 
        
        </main>

        <Footer/>
      </div>
    </div>
  )
}


export default InstructionsPage;
